import './index.css';

import {App} from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { SPAApp } from './lib/infrastructure/app/SPAApp';
import { Startup } from './lib/infrastructure/app/Startup';

Startup.Run(() => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <SPAApp>
            <App />
        </SPAApp>
      </BrowserRouter>

    </React.StrictMode>
  );

});

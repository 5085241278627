import { Navigate, Route, Routes } from "react-router-dom";

import { NavBar } from "../lib/components/nav-bar/NavBar";
import { ProjectsHomePage } from "../features/projects/pages/projects-home/ProjectsHomePage";
import { ProjectsModule } from "./lv1/ProjectsModule";

export function AppRoutingModule() {

    return (
        <>
            <NavBar />
            <Routes>
                <Route path="/projects">
                    <Route index element={<ProjectsHomePage />} />
                    
                    <Route path=":projectId">
                        <Route index element={<Navigate to="logs" />} />
                        <Route path="*" element={<ProjectsModule />} />
                    </Route>
                </Route> 
                
                <Route path="*" element={<Navigate to="/notfound"/>}/>
            </Routes>
        </>
    )
}

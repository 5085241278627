import { CircleArrowSVG } from "../../../../lib/assets/icons";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { LogDTO } from "../../../../common/models/LogDTO";
import { logLevelOptions } from "./LogsHomePage";
import { useNavigate } from "react-router-dom";

interface IContextProps { log: LogDTO }
export const ContextCell = (props: IContextProps) => {
    return (
        <FlexLayout direction='vertical' verticalAlign="center" >
            <div >SID: {props.log.sessionId}</div>
            <div >CID: {props.log.correlationId}</div>
            {props.log.actor?<div >Actor: {props.log.actor}</div> : null}
        </FlexLayout>

    );
};

interface IOpenLogCellProps { log: LogDTO }
export const OpenLogCell = (props: IOpenLogCellProps) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(props.log.id||"");
    };
    
    return (
        <div className="options-cell">
            <CircleArrowSVG onClick={handleNavigate} />
        </div>
    );
};

interface ILogLevelCellProps { log: LogDTO }
export function LogLevelCell(props: ILogLevelCellProps) {
    if (logLevelOptions[props.log.level]) {
        return <div>{logLevelOptions[props.log.level].name}</div>
    }
    return <div>*null*</div>
}